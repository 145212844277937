import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'

const routes = [
  {
    meta: {
      title: 'Login',
      fullScreen: true,
      reqGuest: true
    },
    path: '/',
    name: 'login',
    component: Login
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard',
      reqAuth: true
    },
    path: '/dashboard',
    name: 'dashboard',
    component: Home
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Custom Ads',
      reqAuth: true
    },
    path: '/custom-ads',
    name: 'custom-ads',
    component: () => import(/* webpackChunkName: "ads" */ '@/views/Ads.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Create Ad',
      reqAuth: true
    },
    path: '/custom-ads/create-ad',
    name: 'create-ad',
    component: () => import(/* webpackChunkName: "createAd" */ '@/views/CreateAd.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'View Ad',
      reqAuth: true
    },
    path: '/custom-ads/:id',
    name: 'view-ad',
    component: () => import(/* webpackChunkName: "viewAd" */ '@/views/ViewAd.vue')
  },
  // {
  //   // Document title tag
  //   // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
  //   meta: {
  //     title: 'create-ad'
  //   },
  //   path: '/create-ad',
  //   name: 'create-ad',
  //   component: () => import(/* webpackChunkName: "createAd" */ '@/views/CreateAd.vue')
  // },
  {
    meta: {
      title: 'Bubbles',
      reqAuth: true
    },
    path: '/bubbles',
    name: 'bubbles',
    component: () => import(/* webpackChunkName: "bubbles" */ '@/views/Bubbles.vue')
  },
  {
    meta: {
      title: 'View Bubble',
      reqAuth: true
    },
    path: '/bubbles/:id',
    name: 'view-bubble',
    component: () => import(/* webpackChunkName: "bubble" */ '@/views/ViewBubble.vue')
  },
  {
    meta: {
      title: 'Profile',
      reqAuth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "bubbles" */ '@/views/AdminProfile.vue')
  },
  {
    meta: {
      title: 'Tables',
      reqAuth: true
    },
    path: '/tables',
    name: 'tables',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Tables.vue')
  },
  {
    meta: {
      title: 'Forms',
      reqAuth: true
    },
    path: '/forms',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/Forms.vue')
  },
  {
    meta: {
      title: 'User Profile',
      reqAuth: true
    },
    path: '/user-profile',
    name: 'user-profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    meta: {
      title: 'Responsive layout',
      reqAuth: true
    },
    path: '/responsive',
    name: 'responsive',
    component: () => import(/* webpackChunkName: "responsive" */ '@/views/Responsive.vue')
  },
  {
    meta: {
      title: 'Login',
      fullScreen: true,
      reqAuth: true
    },
    path: '/login',
    name: 'login-example',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    meta: {
      title: 'Error',
      fullScreen: true,
      reqAuth: true
    },
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Users',
      reqAuth: true
    },
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'User',
      reqAuth: true
    },
    path: '/users/:id',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '@/views/Profile.vue')
  },
  {
    meta: {
      title: 'Under Development',
      reqAuth: true
    },
    path: '/under-development',
    name: 'under-development',
    component: () => import(/* webpackChunkName: "ud" */ '@/views/Development.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'FAQs',
      reqAuth: true
    },
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "user" */ '@/views/FAQs.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Create FAQ',
      reqAuth: true
    },
    path: '/faqs/add',
    name: 'create-faq',
    component: () => import(/* webpackChunkName: "CreateFaq" */ '@/views/CreateFaq.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Edit FAQ',
      reqAuth: true
    },
    path: '/faqs/:id',
    name: 'edit-faq',
    component: () => import(/* webpackChunkName: "viewAd" */ '@/views/EditFaq.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Set Timeout',
      reqAuth: true
    },
    path: '/settings/set-timeout',
    name: 'set-timeout',
    component: () => import(/* webpackChunkName: "setTimeout" */ '@/views/SetTimeout.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Filter Words',
      reqAuth: true
    },
    path: '/settings/filter-words',
    name: 'filter-words',
    component: () => import(/* webpackChunkName: "ads" */ '@/views/FilterWords.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Add Word',
      reqAuth: true
    },
    path: '/settings/add-word',
    name: 'add-word',
    component: () => import(/* webpackChunkName: "createAd" */ '@/views/AddWord.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Edit Filter Word',
      reqAuth: true
    },
    path: '/settings/edit-word/:id',
    name: 'edit-word',
    component: () => import(/* webpackChunkName: "viewAd" */ '@/views/EditWord.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Reported Users',
      reqAuth: true
    },
    path: '/reported-users',
    name: 'reported-users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/ReportedUsers.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Active Users',
      reqAuth: true
    },
    path: '/active-users',
    name: 'active-users',
    component: () => import(/* webpackChunkName: "viewAd" */ '@/views/ActiveUsersList.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'In-active Users',
      reqAuth: true
    },
    path: '/inactive-users',
    name: 'inactive-users',
    component: () => import(/* webpackChunkName: "viewAd" */ '@/views/InActiveUsersList.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Active Bubbles',
      reqAuth: true
    },
    path: '/active-bubbles',
    name: 'active-bubbles',
    component: () => import(/* webpackChunkName: "viewAd" */ '@/views/ActiveBubblesList.vue')
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'In-active Bubbles',
      reqAuth: true
    },
    path: '/inactive-bubbles',
    name: 'inactive-bubbles',
    component: () => import(/* webpackChunkName: "viewAd" */ '@/views/InActiveBubblesList.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (token) {
    if (to.meta.reqAuth && token) {
      next()
      return
    } else {
      next({ name: 'under-development' })
    }
  }

  if (to.meta.reqGuest) {
    next()
    return
  }
  next({ name: 'login' })
})

export default router
