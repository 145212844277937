<script setup>
import { reactive, onMounted } from 'vue'
import { mdiLoading } from '@mdi/js'

const anim = reactive({
  dots: ''
})

const loadingAnim = () => {
  setInterval(() => {
    if (anim.dots === '...') {
      anim.dots = ''
    } else {
      anim.dots += '.'
    }
  }, 200)
}

onMounted(() => {
  loadingAnim()
})
</script>

<template>
  <div class="loader">
    <div class="wrapper">
      <div class="icon animate-spin ">
        <svg
          viewBox="0 0 24 24"
          width="1em"
          height="1em"
          class="inline-block"
        >
          <path
            fill="currentColor"
            :d="mdiLoading"
          />
        </svg>
      </div>

      <h2>
      <span>
        Loading{{ anim.dots }}
      </span>
      </h2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .loader{
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //display: none;

    .icon{
      animation-duration: 700ms;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8026AB;
      font-size: 200px;
    }
    h2{
      text-align: center;
      margin-top: 1rem;
      font-size: 2rem;
      color: #4b5563;

      span{
        display: inline-flex;
        min-width: 140px;
      }
    }
  }
</style>
