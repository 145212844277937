import {
  mdiBullhornVariantOutline,
  mdiCogOutline,
  mdiClockEditOutline,
  mdiAccountMultipleOutline,
  mdiCircleMultipleOutline, mdiHelpCircleOutline, mdiAlphaACircleOutline, mdiViewDashboard
} from '@mdi/js'

export default [
  [
    {
      to: '/dashboard',
      label: 'Dashboard',
      icon: mdiViewDashboard
    },
    {
      to: '/custom-ads',
      label: 'Custom Ads',
      icon: mdiBullhornVariantOutline
    },
    {
      to: '/users',
      label: 'User Profiles',
      icon: mdiAccountMultipleOutline
    },
    {
      to: '/bubbles',
      label: 'Bubbles',
      icon: mdiCircleMultipleOutline
    },
    {
      to: '/faqs',
      label: 'Faqs',
      icon: mdiHelpCircleOutline
    },
    {
      label: 'Settings',
      subLabel: 'Timeout',
      icon: mdiCogOutline,
      menu: [
        {
          to: '/settings/set-timeout',
          label: 'Timeout',
          icon: mdiClockEditOutline
        },
        {
          to: '/settings/filter-words',
          label: 'Filter Words',
          icon: mdiAlphaACircleOutline
        }
      ]
    },
    {
      to: '/reported-users',
      label: 'Reported User',
      icon: mdiAccountMultipleOutline
    }
  ]
]
