<script setup>
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'

const props = defineProps({
  username: {
    type: String,
    default: null
  },
  avatar: {
    type: String,
    default: null
  },
  api: {
    type: String,
    default: 'api/avataaars'
  }
})

const store = useStore()

const lightBgStyle = computed(() => store.state.lightBgStyle)

onMounted(() => {

})
</script>

<template>
  <div>
    <div v-if="props.avatar" class="rounded-full dark:bg-gray-800 overflow-hidden h-56 w-56">
      <img
        :src="props.avatar"
        :alt="props.username"
        class="block h-full w-full max-w-full"
        :class="lightBgStyle"
      >
    </div>

    <img
      v-else
      :src="props.avatar"
      :alt="props.username"
      class="rounded-full block h-auto w-full max-w-full dark:bg-gray-800"
      :class="lightBgStyle"
    >
  </div>
</template>
