import { createStore } from 'vuex'
import axios from 'axios'
import { darkModeKey, styleKey } from '@/config.js'
import * as styles from '@/styles.js'
// import { createToaster } from '@meforma/vue-toaster'
const BASE_URL = process.env.VUE_APP_API_URL
// const BASE_URL = 'http://localhost:8090/'
// const token = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).token
// const toaster = createToaster({ /* options */ })
console.log('BASE_URL', process.env.VUE_APP_API_URL, BASE_URL)
export default createStore({
  state: {
    /* Styles */
    lightBorderStyle: '',
    lightBgStyle: '',
    asideStyle: '',
    asideBrandStyle: '',
    asideMenuCloseLgStyle: '',
    asideMenuLabelStyle: '',
    asideMenuItemStyle: '',
    asideMenuItemActiveStyle: '',
    asideMenuItemInactiveStyle: '',
    asideSubmenuListStyle: '',
    navBarItemLabelStyle: '',
    navBarItemLabelHoverStyle: '',
    navBarItemLabelActiveColorStyle: '',
    navBarMenuListUpperLabelStyle: '',
    tableTrStyle: '',
    tableTrOddStyle: '',
    overlayStyle: '',
    customAds: '',
    singleAd: '',
    singleUser: '',
    allBubbles: '',
    singleBubble: '',
    pagination: {},
    listFAQs: '',
    singleFaq: '',
    settings: '',
    isLoading: false,

    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* fullScreen - fullscreen form layout (e.g. login page) */
    isFullScreen: true,

    /* Aside */
    isAsideMobileExpanded: false,
    isAsideLgActive: false,

    /* Dark mode */
    darkMode: true,

    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    clients: [],
    history: [],
    listWords: '',
    singleWord: '',
    dataCounter: ''
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    setPagination (state, pagination) {
      state.pagination = pagination
    },

    /* Styles */
    styles (state, payload) {
      for (const key in payload) {
        if (['body', 'html'].includes(key)) {
          continue
        }

        state[`${key}Style`] = payload[key]
      }
    },
    loader (state, payload) {
      state.isLoading = payload
    },
    allAds (state, payload) {
      state.customAds = payload
    },
    singleAd (state, payload) {
      state.singleAd = payload
    },
    singleUser (state, payload) {
      state.singleUser = payload
    },
    allBubbles (state, payload) {
      state.allBubbles = payload
    },
    singleBubble (state, payload) {
      state.singleBubble = payload
    },
    allFAQs (state, payload) {
      state.listFAQs = payload
    },
    singleFaq (state, payload) {
      state.singleFaq = payload
    },
    settings (state, payload) {
      state.settings = payload
    },
    listWords (state, payload) {
      state.listWords = payload
    },
    singleWord (state, payload) {
      state.singleWord = payload
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    },
    setUser (state, payload) {
      state.isAuthenticated = payload.isAuthenticated
      localStorage.setItem('user', payload.user)
    },
    dataCounter (state, payload) {
      state.dataCounter = payload
    }
  },
  actions: {
    setStyle ({ commit, dispatch }, payload) {
      const style = styles[payload] ?? styles.basic

      document.body.className = style.body
      document.documentElement.className = style.html

      if (localStorage[styleKey] !== payload) {
        localStorage.setItem(styleKey, payload)
      }

      commit('styles', style)
    },
    asideMobileToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

      document.getElementById('app').classList[isShow ? 'add' : 'remove']('ml-60', 'lg:ml-0')

      document.documentElement.classList[isShow ? 'add' : 'remove']('m-clipped')

      commit('basic', {
        key: 'isAsideMobileExpanded',
        value: isShow
      })
    },
    asideLgToggle ({ commit, state }, payload = null) {
      commit('basic', { key: 'isAsideLgActive', value: payload !== null ? payload : !state.isAsideLgActive })
    },

    fullScreenToggle ({ commit, state }, value) {
      commit('basic', { key: 'isFullScreen', value })

      document.documentElement.classList[value ? 'add' : 'remove']('full-screen')
    },
    darkMode ({ commit, state }, payload = null) {
      const value = payload !== null ? payload : !state.darkMode

      document.documentElement.classList[value ? 'add' : 'remove']('dark')

      localStorage.setItem(darkModeKey, value ? '1' : '0')

      commit('basic', {
        key: 'darkMode',
        value
      })
    },
    async fetchAds ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('allAds', {
              key: payload,
              value: r.data.data.docs
            })

            const pagination = {
              limit: r.data.data.limit,
              page: r.data.data.page,
              total: r.data.data.totalDocs
            }
            commit('setPagination', pagination)
          }

          return r
        })
        .catch(error => {
          return error.response
        })
    },
    async fetchAd ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('singleAd', {
              key: payload,
              value: r.data.data
            })
          }
          return r
        })
        .catch(error => {
          return error.response
        })
    },
    async createAd ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .post(BASE_URL + 'api/admin/ads/add', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async updateAd ({ commit }, payload) {
      return localStorage.getItem('token') &&
        await axios
          .post(BASE_URL + 'api/admin/ads/edit', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
          .then(function (response) {
            return response
          })
          .catch(error => {
            return error.response
          })
    },
    async updatePassword ({ commit }, payload) {
      return localStorage.getItem('token') &&
        await axios
          .post(BASE_URL + 'api/admin/settings/change-password', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
          .then(function (response) {
            return response
          })
          .catch(error => {
            return error.response
          })
    },
    async fetchUser ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('singleUser', {
              key: payload,
              value: r.data.data
            })
            const pagination = {
              limit: r.data.data.limit,
              page: r.data.data.page,
              total: r.data.data.totalDocs
            }
            commit('setPagination', pagination)
          }
          return r
        })
        .catch(error => {
          return error.response
        })
    },
    fetchBubbles ({ commit }, payload) {
      localStorage.getItem('token') &&
      axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('allBubbles', {
              key: payload,
              value: r.data.data.docs
            })

            const pagination = {
              limit: r.data.data.limit,
              page: r.data.data.page,
              total: r.data.data.totalDocs
            }
            commit('setPagination', pagination)
          }
        })
        .catch(error => {
          alert(error.message)
        })
    },
    async fetchBubble ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('singleBubble', {
              key: payload,
              value: r.data.data
            })
          }

          return r
        })
        .catch(error => {
          return error.response
        })
    },
    async fetchFAQs ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('allFAQs', {
              key: payload,
              value: r.data.data.docs
            })

            const pagination = {
              limit: r.data.data.limit,
              page: r.data.data.page,
              total: r.data.data.totalDocs
            }
            commit('setPagination', pagination)
          }

          return r
        })
        .catch(error => {
          return error.response
        })
    },
    async createFaq ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .post(BASE_URL + 'api/admin/faq/add', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async fetchFaq ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('singleFaq', {
              key: payload,
              value: r.data.data
            })
          }

          return r
        })
        .catch(error => {
          return error.response
        })
    },
    async updateFaq ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .post(BASE_URL + 'api/admin/faq/edit', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async fetchSettings ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('settings', {
              key: payload,
              value: r.data.data
            })
          }

          return r
        })
        .catch(error => {
          return error.response
        })
    },
    async SetTimeout ({ commit }, payload) {
      return await axios
        .post(BASE_URL + 'api/admin/settings/add', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async ActivateBubble ({ commit }, payload) {
      return await axios
        .post(BASE_URL + 'api/admin/bubbles/activate', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    fetchWords ({ commit }, payload) {
      return localStorage.getItem('token') &&
      axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('listWords', {
              key: payload,
              value: r.data.data.word[0].filterWords
            })

            const pagination = {
              limit: r.data.data.pagination.limit,
              page: r.data.data.pagination.page,
              total: r.data.data.pagination.totalDocs
            }
            commit('setPagination', pagination)
          }
          return r
        })
        .catch(error => {
          alert(error.message)
        })
    },
    async addWord ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .post(BASE_URL + 'api/admin/settings/add-words', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async getWord ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('singleWord', {
              key: payload,
              value: r.data.data[0].filterWords[0]
            })
          }

          return r
        })
        .catch(error => {
          return error.response
        })
    },
    async updateWord ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .post(BASE_URL + 'api/admin/settings/edit-word', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async login ({ commit }, payload) {
      return await axios
        .post(BASE_URL + 'api/admin/login', payload.user)
        .then(response => {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async EditUserState ({ commit }, payload) {
      return await axios
        .post(BASE_URL + 'api/admin/users/edit-state', payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(function (response) {
          return response
        })
        .catch(error => {
          return error.response
        })
    },
    async fetchCounters ({ commit }, payload) {
      return localStorage.getItem('token') &&
      await axios
        .get(BASE_URL + payload, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((r) => {
          if (r.data && r.data.data) {
            commit('dataCounter', {
              key: payload,
              value: {
                activeUsers: r.data.data.users.filter(e => e._id === true),
                inActiveUsers: r.data.data.users.filter(e => e._id === false),
                activeBubbles: r.data.data.bubbles.filter(e => e._id === true),
                inActiveBubbles: r.data.data.bubbles.filter(e => e._id === false)
              }
            })
          }
          return r
        })
        .catch(error => {
          return error.response
        })
    },
    setLoader ({ commit }, payload) {
      commit('loader', payload)
    }
  },
  modules: {
  }
})
