<script setup>
import { computed, ref, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import {
  mdiAccountMultiple,
  mdiCircleMultipleOutline
} from '@mdi/js'
import * as chartConfig from '@/components/Charts/chart.config.js'
import MainSection from '@/components/MainSection.vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import CardWidget from '@/components/CardWidget.vue'
import { createToaster } from '@meforma/vue-toaster'
import Loader from '@/components/Loader'

const titleStack = ref(['Admin', 'Dashboard'])
const loader = computed(() => store.state.isLoading)

const chartData = ref(null)
const toaster = createToaster()

const fillChartData = () => {
  chartData.value = chartConfig.sampleChartData()
}

onMounted(() => {
  fillChartData()
})

const users = ref(0)
const deActivatedUsers = ref(0)
const bubbles = ref(0)
const deActivatedBubbles = ref(0)

const store = useStore()
onBeforeMount(() => {
  store.dispatch('setLoader', true)
  store.dispatch('fetchCounters', 'api/admin/dashboard/counters').then(resp => {
    if (resp) {
      const activeUsers = resp.data.data.users.filter(e => e._id === true)
      const InActiveUsers = resp.data.data.users.filter(e => e._id === false)
      users.value = activeUsers[0]?.count ? activeUsers[0].count : 0
      deActivatedUsers.value = InActiveUsers[0]?.count ? InActiveUsers[0].count : 0

      const activeBubbles = resp.data.data.bubbles.filter(e => e._id === true)
      const InActiveBubbles = resp.data.data.bubbles.filter(e => e._id === false)
      bubbles.value = activeBubbles[0]?.count ? activeBubbles[0].count : 0
      deActivatedBubbles.value = InActiveBubbles[0]?.count ? InActiveBubbles[0].count : 0

      store.dispatch('setLoader', false)

      if (resp.status !== 200) {
        toaster.error(`Error! ${resp.statusText}`)
      }
    }
  })
})
// const counters = computed(() => store.state.dataCounter)

// const activeBubble = counters.value.bubbles.filter(e => e._id === true)
// console.log('counters.value', counters.value, counters)
</script>

<template>
  <Loader v-if="loader" />
  <title-bar :title-stack="titleStack" />
  <hero-bar>Dashboard</hero-bar>
  <main-section>
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6">
      <router-link to="/active-users">
        <card-widget
          trend="Active"
          trend-type="up"
          color="text-emerald-500"
          :icon="mdiAccountMultiple"
          :number="users"
          label="Users"
        />
      </router-link>

      <router-link to="/inactive-users">
        <card-widget
          trend="Deactivated"
          trend-type="down"
          color="text-blue-500"
          :icon="mdiAccountMultiple"
          :number="deActivatedUsers"
          label="Users"
        />
      </router-link>
      <router-link to="/active-bubbles">
        <card-widget
          trend="Active"
          trend-type="up"
          color="text-emerald-500"
          :icon="mdiCircleMultipleOutline"
          :number="bubbles"
          label="Bubbles"
        />
      </router-link>
      <router-link to="/inactive-bubbles">
        <card-widget
          trend="Deactivated"
          trend-type="down"
          color="text-blue-500"
          :icon="mdiCircleMultipleOutline"
          :number="deActivatedBubbles"
          label="Bubbles"
        />
      </router-link>
    </div>
  </main-section>
</template>
