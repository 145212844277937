<script setup>
import { reactive, onBeforeMount, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { mdiAccount, mdiAsterisk } from '@mdi/js'
import FullScreenSection from '@/components/FullScreenSection.vue'
import CardComponent from '@/components/CardComponent.vue'
import CheckRadioPicker from '@/components/CheckRadioPicker.vue'
import Field from '@/components/Field.vue'
import Control from '@/components/Control.vue'
import Divider from '@/components/Divider.vue'
import JbButton from '@/components/JbButton.vue'
import JbButtons from '@/components/JbButtons.vue'
import Notification from '@/components/Notification.vue'
import Loader from '@/components/Loader.vue'
import { createToaster } from '@meforma/vue-toaster'

const toaster = createToaster({ /* options */ })
const store = useStore()
const router = useRouter()

onBeforeMount(() => {
  // eslint-disable-next-line no-unused-expressions
  localStorage.getItem('user')
    ? router.push({ path: '/dashboard' })
    : false
})

const form = reactive({
  username: '',
  password: '',
  remember: ['remember']
})

if (localStorage.getItem('loggedin')) {
  router.push('/dashboard')
}

const loader = computed(() => store.state.isLoading)

const submit = () => {
  if (form.username && form.password) {
    store.dispatch('setLoader', true)
    store.dispatch('login', { user: form }).then((res) => {
      if (res) {
        store.dispatch('setLoader', false)
        if (res.status === 200 || res.statusText === 'OK') {
          toaster.success(res.data.message)
          router.push('/dashboard')
          const user = JSON.stringify(res.data)
          localStorage.setItem('token', res.data.token)
          store.commit('setUser', { isAuthenticated: true, user: user })
        } else {
          store.dispatch('setLoader', false)
          toaster.error(`Error! ${res.data.message}`)
        }
      } else {
        store.dispatch('setLoader', false)
        toaster.error('Error! Please check your internet and try again')
      }
    })
  } else {
    store.dispatch('setLoader', false)
    toaster.error('Please fill the information to login')
  }
}
</script>

<template>
  <full-screen-section
    v-slot="{ cardClass, cardRounded }"
    bg="login"
  >
    <Loader v-if="loader" />
    <card-component
      :class="cardClass"
      :rounded="cardRounded"
      form
      @submit.prevent="submit"
    >
      <notification
        v-show="form.showError === true"
        color="danger"
        :icon="mdiAlertCircle"
        :outline="notificationsOutline"
      >
        <b>{{ form.error }}</b>.
      </notification>
      <field
        label="User"
        help="Please enter your login"
      >
        <control
          v-model="form.username"
          :icon="mdiAccount"
          name="username"
          autocomplete="false"
        />
      </field>

      <field
        label="Password"
        help="Please enter your password"
      >
        <control
          v-model="form.password"
          :icon="mdiAsterisk"
          type="password"
          name="password"
          autocomplete="false"
        />
      </field>

      <check-radio-picker
        v-model="form.remember"
        name="remember"
        :options="{ remember: 'Remember' }"
      />

      <divider />

      <jb-buttons>
        <jb-button
          type="submit"
          color="info"
          label="Login"
        />
      </jb-buttons>
    </card-component>
  </full-screen-section>
</template>
